import { Injectable, NgZone } from '@angular/core';
import { auditTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HomePageService {

	// An observable to use for redrawing charts
	// usefull when many charts are drawn on the same page
	// and conflict on redrawn from ChangeDetection
	public redrawingChartsSink$ = new Subject<void>();

	private _redrawingChartsTimer$ = new Subject<void>();
	public redrawingChartsTimer$ = this._redrawingChartsTimer$.asObservable();

	constructor(
        protected zone: NgZone,
	) {
		this.redrawingChartsSink$.pipe(
			auditTime(500),
		).subscribe(_ => this._redrawingChartsTimer$.next())
	}
}
