import { on, createReducer } from '@ngrx/store';

import { withEntityReducer, withListReducer } from '../functions';

import * as featureActions from './actions';
import { initialState, IHomeActionsState } from './state';



export const HomeActionsReducer = createReducer<IHomeActionsState>(
	initialState,

	...withEntityReducer<IHomeActionsState>('turnoverData', featureActions.turnoverDataActions),
	...withEntityReducer<IHomeActionsState>('averageOverdueData', featureActions.averageOverdueDataActions),
	...withEntityReducer<IHomeActionsState>('outstandingAmountByDunningLevels', featureActions.outstandingAmountByDunningLevelsActions),
	...withListReducer<IHomeActionsState>('topOutstandingList', featureActions.topOutstandingAmountActions),
	...withEntityReducer<IHomeActionsState>('topDebtorsWithClaims', featureActions.topDebtorsWithClaimsActions),
	...withEntityReducer<IHomeActionsState>('ageingBalance', featureActions.ageingBalanceActions),
	...withEntityReducer<IHomeActionsState>('ageingBalance', featureActions.ageingBalanceDetailedActions),
	...withEntityReducer<IHomeActionsState>('dsoMonitoring', featureActions.dsoMonitoringActions),
	...withEntityReducer<IHomeActionsState>('estimatedCashing', featureActions.estimatedCashingKpisActions),
	...withEntityReducer<IHomeActionsState>('superDebtorScorings', featureActions.superDebtorScoringsActions),
	...withEntityReducer<IHomeActionsState>('turnoverWorldMapData', featureActions.turnoverWorldMapDataActions),

	on(featureActions.UpdateIsEmpty, (state, action) => ({
		...state,
		isEmpty: action.isEmpty,
	})),

	on(featureActions.ShouldBeReloaded, (state, action) => ( {
		...state,
		shouldBeReloaded: action.scope
	})),
);
