
import { createListSlice, IFilter } from '@aston/foundation';
import { ICountryMarker } from '@aston/charts/models';

import { DsoModel, IHomeEstimatedCashingKpis, IHomeOutstandingByDunningLevelChartData, IHomeTurnoverChartData, ITopDebtorsWithClaims, IHomeScoringSlices, TopOutstandingList, IHomeAverageOverdueChartData, IHomeAgeingBalanceChartData } from '../../home-module/models/home.model';
import { IStoreEntityStateSlice, createEntitySlice, IStoreListStateSlice } from '../functions';
import { TopOutstandingSortProp } from '../../shared-module/enums';

export interface IHomeActionsState {
	isEmpty: boolean;
	isLoading: boolean;
	error: string;
	shouldBeReloaded: boolean;
	outstandingAmountByDunningLevels: IStoreEntityStateSlice<IHomeOutstandingByDunningLevelChartData>;
	superDebtorScorings: IStoreEntityStateSlice<IHomeScoringSlices>;
	ageingBalance: IStoreEntityStateSlice<IHomeAgeingBalanceChartData>;
	turnoverData: IStoreEntityStateSlice<IHomeTurnoverChartData>;
	averageOverdueData: IStoreEntityStateSlice<IHomeAverageOverdueChartData>;
	estimatedCashing: IStoreEntityStateSlice<IHomeEstimatedCashingKpis>;
	topOutstandingList: IStoreListStateSlice<TopOutstandingList, TopOutstandingSortProp, IFilter>;
	topDebtorsWithClaims: IStoreEntityStateSlice<ITopDebtorsWithClaims>;
	dsoMonitoring: IStoreEntityStateSlice<DsoModel[]>;
	turnoverWorldMapData: IStoreEntityStateSlice<ICountryMarker[]>;
}

export const initialState: IHomeActionsState = {
	isEmpty: undefined,
	isLoading: false,
	error: null,
	shouldBeReloaded: false,
	outstandingAmountByDunningLevels: createEntitySlice(),
	superDebtorScorings: createEntitySlice(),
	ageingBalance: createEntitySlice(),
	turnoverData: createEntitySlice(),
	averageOverdueData: createEntitySlice(),
	estimatedCashing: createEntitySlice(),
	topDebtorsWithClaims: createEntitySlice(),
	topOutstandingList: createListSlice({ prop: TopOutstandingSortProp.lastDunningDate, isDesc: true }),
	dsoMonitoring: createEntitySlice(),
	turnoverWorldMapData: createEntitySlice()
};
