

import { SuperCustomersListSortProp, CustomersListSortProp } from 'apps/federation/src/app/customers-module/enums';
import { IListState } from '@aston/foundation';
import { ISuperCustomersList, ISuperCustomersListFilter } from 'apps/federation/src/app/customers-module/models';
import { createDefaultListState } from '@aston/foundation';

import { createListSlice, IStoreListStateSlice } from '../functions';
import { AppConstants } from '../../app.constants';

import { FeatureName } from './feature';


export interface ISuperCustomersState {
	isLoading: boolean;
	error: string;

	list: IStoreListStateSlice<ISuperCustomersList, SuperCustomersListSortProp, ISuperCustomersListFilter>;

	debtorsDefaultPaging: IListState<CustomersListSortProp>;
}

export const initialState: ISuperCustomersState = {
	isLoading: false,
	error: null,

	list: createListSlice({ prop: SuperCustomersListSortProp.EXPIRED_OUTSTANDING_AMOUNT, isDesc: true }, AppConstants.LIST_PAGE_SIZE_DEFAULT, [], `${FeatureName}.list`),

	debtorsDefaultPaging: createDefaultListState(CustomersListSortProp.EXPIRED_OUTSTANDING_AMOUNT, true, AppConstants.LIST_PAGE_SIZE_DEFAULT),
};
