
import { Action, createAction, props } from '@ngrx/store';
import { ISuperCustomersList, ICustomersList, ISuperCustomersListFilter } from 'apps/federation/src/app/customers-module/models';
import { CustomersListSortProp, SuperCustomersListSortProp } from 'apps/federation/src/app/customers-module/enums';
import { IListState } from '@aston/foundation';

import { createListActions, createEntityActions, createDebugAction } from '../functions';
import { CorrelationParams, newAggregationId } from '../models/correlated-actions.model';

const ActionPrefix = 'Super Customers';

export const Debug = createDebugAction(`[${ActionPrefix}] `);

export const superCustomersListActions = createListActions<ISuperCustomersList, SuperCustomersListSortProp, ISuperCustomersListFilter>(ActionPrefix, 'Super Customers List');
export const {
	LoadListRequest: LoadSuperCustomersRequest,
	LoadListSuccess: LoadSuperCustomersSuccess,
	LoadListFailure: LoadSuperCustomersFailure,
	ListSettings: UpdateSuperCustomersSettings,
	AddListFilters: AddSuperCustomersFilters,
	ResetListFilters: ResetSuperCustomersFilters,
	RemoveListFilters: RemoveSuperCustomersFilters,
	ExportListRequest: ExportSuperCustomersRequest,
	ExportListSuccess: ExportSuperCustomersSuccess,
	ExportListFailure: ExportSuperCustomersFailure,
} = superCustomersListActions;

export const InitRequest = createAction(`[${ActionPrefix}] InitRequest`,
	(correlationParams: CorrelationParams = {correlationId: newAggregationId()}) => ({correlationParams})
);

export const AfterInitRequest = createAction(`[${ActionPrefix}] AfterInitRequest`,props<{ dispatch: Action[] }>());

export const InitSuccess = createAction(`[${ActionPrefix}] InitSuccess`,
	(correlationParams?: CorrelationParams) => ({correlationParams}));

export const InitFailure = createAction(`[${ActionPrefix}] InitFailure`,
	props<{ correlationParams?: CorrelationParams, error: any }>()
);

export const ToggleCustomers = createAction(`[${ActionPrefix}] ToggleCustomers`,
	props<{ id: number }>()
);

export const LoadCustomersRequest = createAction(`[${ActionPrefix}] LoadCustomersRequest`,
	props<{ id: number }>()
);

export const LoadCustomersFailure = createAction(`[${ActionPrefix}] LoadCustomersFailure`,
	props<{ id: number, error: any }>()
);

export const LoadCustomersSuccess = createAction(`[${ActionPrefix}] LoadCustomersSuccess`,
	props<{ id: number, customers: ICustomersList }>()
);

export const UpdateCustomersSettings = createAction(`[${ActionPrefix}] UpdateCustomersSettings`,
	props<{ id: number, paging: IListState<CustomersListSortProp> }>()
);

export const LoadAttachedCustomersRequest = createAction(`[${ActionPrefix}] LoadAttachedCustomersRequest`,
	props<{ id: number }>()
);

export const LoadAttachedCustomersFailure = createAction(`[${ActionPrefix}] LoadAttachedCustomersFailure`,
	props<{ id: number, error: any }>()
);

export const LoadAttachedCustomersSuccess = createAction(`[${ActionPrefix}] LoadAttachedCustomersSuccess`,
	props<{ id: number, attachedCustomers: ICustomersList }>()
);

export const UpdateAttachedCustomersSettings = createAction(`[${ActionPrefix}] UpdateAttachedCustomersSettings`,
	props<{ id: number, paging: IListState<CustomersListSortProp> }>()
);

export const customersIdsActions = createEntityActions<number[]>(ActionPrefix, 'Super Customers IDs');
export const {
	LoadEntityRequest: LoadIdsListRequest,
	LoadEntitySuccess: LoadIdsListSuccess,
	LoadEntityFailure: LoadIdsListFailure,
} = customersIdsActions;
