
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import * as AppStoreSelectors from 'apps/federation/src/app/root-store/app-store/selectors';
import { ExportResponse } from '@aston/foundation';

import {
	ISuperCustomerAgeingBalanceChartData,
	ISuperCustomerAverageLitigationDelayChartData,
	ISuperCustomerAverageOverdueChartData,
	ISuperCustomerDSOChartData,
	ISuperCustomerEstimatedCashingChartData,
	SuperCustomerEstimatedCashingMode,
	ISuperCustomerOutstandingByDunningLevelChartData,
	ISuperCustomerScoringChartData,
	ISuperCustomerTurnoverChartData,
} from '../models';

@Injectable({
	providedIn: 'root'
})
export class SuperCustomerChartsService {

	constructor(
		private http: HttpClient,
		private store: Store,
	) { }

	chartDataForDSO(superDebtorId: number): Observable<ISuperCustomerDSOChartData> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<ISuperCustomerDSOChartData>(`${config.apiUrl}/api/SuperDebtors/${superDebtorId}/kpi/dso`)),
		);
	}

	chartDataForTurnover(superDebtorId: number): Observable<ISuperCustomerTurnoverChartData> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<ISuperCustomerTurnoverChartData>(`${config.apiUrl}/api/SuperDebtors/${superDebtorId}/kpis/totalRevenues`)),
		);
	}

	chartDataForScoring(superDebtorId: number): Observable<ISuperCustomerScoringChartData> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<ISuperCustomerScoringChartData>(`${config.apiUrl}/api/SuperDebtors/${superDebtorId}/kpis/scoring`)),
		);
	}

	chartDataForOutstandingByDunningLevel(superDebtorId: number): Observable<ISuperCustomerOutstandingByDunningLevelChartData> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<ISuperCustomerOutstandingByDunningLevelChartData>(`${config.apiUrl}/api/SuperDebtors/${superDebtorId}/kpis/outstanding`)),
		);
	}

	chartDataForAverageOverdue(superDebtorId: number): Observable<ISuperCustomerAverageOverdueChartData> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<ISuperCustomerAverageOverdueChartData>(`${config.apiUrl}/api/SuperDebtors/${superDebtorId}/kpis/averageOverdue`)),
		);
	}

	chartDataForAgeingBalance(superDebtorId: number): Observable<ISuperCustomerAgeingBalanceChartData> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<ISuperCustomerAgeingBalanceChartData>(`${config.apiUrl}/api/SuperDebtors/${superDebtorId}/kpis/ageingBalance`)),
		);
	}

	exportAgeingBalanceKpis(superDebtorId: number): Observable<ExportResponse> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get(`${config.apiUrl}/api/SuperDebtors/${superDebtorId}/kpis/ageingBalance/export`, {observe: 'response', responseType: 'blob'})),
			map(response => new ExportResponse(response))
		);
	}

	exportEstimatedCashingKpis(superDebtorId: number): Observable<ExportResponse> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get(`${config.apiUrl}/api/SuperDebtors/${superDebtorId}/kpis/estimatedCashing/export`, {observe: 'response', responseType: 'blob'})),
			map(response => new ExportResponse(response))
		);
	}

	chartDataForAverageLitigationDelay(superDebtorId: number): Observable<ISuperCustomerAverageLitigationDelayChartData> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<ISuperCustomerAverageLitigationDelayChartData>(`${config.apiUrl}/api/SuperDebtors/${superDebtorId}/kpis/litigation`)),
		);
	}

	chartDataForEstimatedCashing(superDebtorId: number, mode: SuperCustomerEstimatedCashingMode): Observable<ISuperCustomerEstimatedCashingChartData> {
		const modeBool = mode === SuperCustomerEstimatedCashingMode.Estimated ? 'false' : 'true';
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<ISuperCustomerEstimatedCashingChartData>(
				`${config.apiUrl}/api/SuperDebtors/${superDebtorId}/kpis/estimatedCashing?isDelayed=${modeBool}`)),
		);
	}
}
