import { on, createReducer } from '@ngrx/store';
import { removeTransientFilters } from '@aston/foundation';

import { withListReducer } from '../functions';

import * as featureActions from './actions';
import { initialState, ISuperCustomersState } from './state';
import { getSuperCustomerById } from './helpers';



export const superCustomersReducer = createReducer<ISuperCustomersState>(
	initialState,

	...withListReducer<ISuperCustomersState>('list', featureActions.superCustomersListActions),

	on(featureActions.InitRequest, state => ({
		...initialState,
		isLoading: true,
		error: null,
		list: {
			...initialState.list,
			// keep filters: https://astonitf.visualstudio.com/LBP/_workitems/edit/9734
			filters: removeTransientFilters(state.list?.filters || []),
			paging: state.list?.paging || initialState.list?.paging
		}
	})),

	on(featureActions.InitSuccess, state => ({
		...state,
		isLoading: false,
		error: null
	})),

	on(featureActions.InitFailure, (state, action) => ({
		...state,
		isLoading: false,
		error: action.error
	})),

	on(featureActions.ToggleCustomers, (state, action) => {
		const superDebtor = getSuperCustomerById(state, action.id);
		superDebtor.isDebtorsListOpened = !superDebtor.isDebtorsListOpened;
		superDebtor.isAttachedDebtorsListOpened = !superDebtor.isAttachedDebtorsListOpened;

		return {
			...state,
			list: {
				...state.list,
			}
		};
	}),

	on(featureActions.LoadCustomersRequest, (state, action) => {
		const superDebtor = getSuperCustomerById(state, action.id);
		superDebtor.isDebtorsListLoading = true;
		superDebtor.isDebtorsListOpened = true;

		return {
			...state,
			list: {
				...state.list,
			}
		};
	}),

	on(featureActions.LoadCustomersSuccess, (state, action) => {
		const superDebtor = getSuperCustomerById(state, action.id);
		superDebtor.isDebtorsListLoading = false;
		superDebtor.debtorsList = action.customers;
		superDebtor.isDebtorsListInitialized = true;
		superDebtor.debtorsListError = null;

		return {
			...state,
			list: {
				...state.list,
			}
		};
	}),

	on(featureActions.LoadCustomersFailure, (state, action) => {
		const superDebtor = getSuperCustomerById(state, action.id);
		superDebtor.isDebtorsListLoading = false;
		superDebtor.debtorsListError = action.error;

		return {
			...state,
			list: {
				...state.list,
			}
		};
	}),

	on(featureActions.UpdateCustomersSettings, (state, action) => {
		const superDebtor = getSuperCustomerById(state, action.id);
		superDebtor.debtorsListPaging = {
			...superDebtor.debtorsListPaging,
			...action.paging
		};

		return {
			...state,
			list: {
				...state.list,
			}
		};
	}),

	on(featureActions.LoadAttachedCustomersRequest, (state, action) => {
		const superDebtor = getSuperCustomerById(state, action.id);
		superDebtor.isAttachedDebtorsListLoading = true;
		superDebtor.isAttachedDebtorsListOpened = true;

		return {
			...state,
			list: {
				...state.list,
			}
		};
	}),

	on(featureActions.LoadAttachedCustomersSuccess, (state, action) => {
		const superDebtor = getSuperCustomerById(state, action.id);
		superDebtor.isAttachedDebtorsListLoading = false;
		superDebtor.attachedDebtorsList = action.attachedCustomers;
		superDebtor.isAttachedDebtorsListInitialized = true;
		superDebtor.attachedDebtorsListError = null;

		return {
			...state,
			list: {
				...state.list,
			}
		};
	}),

	on(featureActions.LoadAttachedCustomersFailure, (state, action) => {
		const superDebtor = getSuperCustomerById(state, action.id);
		superDebtor.isAttachedDebtorsListLoading = false;
		superDebtor.attachedDebtorsListError = action.error;

		return {
			...state,
			list: {
				...state.list,
			}
		};
	}),

	on(featureActions.UpdateAttachedCustomersSettings, (state, action) => {
		const superDebtor = getSuperCustomerById(state, action.id);
		superDebtor.attachedDebtorsListPaging = {
			...superDebtor.attachedDebtorsListPaging,
			...action.paging
		};

		return {
			...state,
			list: {
				...state.list,
			}
		};
	}),

);
