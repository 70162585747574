import { Action, createReducer, on } from '@ngrx/store';

import { withEntityReducer, withListReducer } from '../functions';

import { initialState, ISuperCustomerPageState } from './state';
import * as featureActions from './actions';



export const superCustomerPageReducer = createReducer<ISuperCustomerPageState, Action>(
	initialState,
	...withEntityReducer<ISuperCustomerPageState>('superCustomerIdentity', featureActions.superCustomerIdentityActions),

	...withListReducer<ISuperCustomerPageState>('superCustomerAccountingDocuments', featureActions.superCustomerAccountingDocumentsActions),
	...withListReducer<ISuperCustomerPageState>('attachedCustomers', featureActions.superCustomerAttachedDebtorsActions),
	...withListReducer<ISuperCustomerPageState>('allAttachedCustomers', featureActions.allSuperCustomerAttachedDebtorsActions),

	on(featureActions.InitSuperCustomerPageRequest, (state, action) => ({
		...( action.id === state.superCustomerId ? state : initialState ),
		isLoading: true,
		error: null,
		superCustomerId: action.id,
	})),
);
