
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { FeatureName } from './feature';
import { HomeActionsReducer } from './reducer';
import { HomeActionsStoreEffects } from './effects';


@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(FeatureName, HomeActionsReducer),
		EffectsModule.forFeature([HomeActionsStoreEffects])
	]
})

export class HomeActionsStoreModule { }
