
import { CountryCodeType, createTypedAction, DunningLevel, EntityActionType, IFilter, IFilterOperator, ListActionType } from '@aston/foundation';
import { ICountryMarker } from '@aston/charts/models';
import { createAction, props } from '@ngrx/store';
import { AccountingDocumentTypeCode } from 'apps/federation/src/app/accounting-module/enums';
import { SuperCustomerEstimatedCashingMode } from 'apps/federation/src/app/customers-module/models';
import { CountMode, OutstandingMode, TopOutstandingSortProp } from 'apps/federation/src/app/shared-module/enums';
import { DsoModel, IHomeEstimatedCashingKpis, IHomeOutstandingByDunningLevelChartData, IHomeTurnoverChartData, ITopDebtorsWithClaims, IHomeScoringSlices, TopOutstandingList, IHomeAverageOverdueChartData, IHomeAgeingBalanceChartData } from 'apps/federation/src/app/home-module/models/home.model';

import { createListActions, createEntityActions } from '../functions';

const ActionPrefix = 'Home Actions';

export const InitHomepage = createAction(`${ActionPrefix} Init Homepage`);
export const InitDashboards = createAction(`${ActionPrefix} Init Dashboards`);
export const UpdateIsEmpty = createAction(`${ActionPrefix} Update Is Empty`, props<{ isEmpty: boolean }>());
export const UpdateNoData = createAction(`${ActionPrefix} Update No Data`, props<{ noData: boolean }>());
export const ShouldBeReloaded = createAction(`${ActionPrefix} should Be Reloaded`, props<{ scope: boolean }>());

export const NavigateToAccountingDocumentsByDunningLevels = createAction(`${ActionPrefix} NavigateToAccountingDocumentsByDunningLevels`, props<{ dunningLevels: DunningLevel[], onlyExpired?: boolean }>());
export const NavigateToAccountingDocumentsByClaimTypes = createAction(`${ActionPrefix} NavigateToAccountingDocumentsByClaimTypes`, props<{ claimTypes: number[] }>());
export const NavigateToAccountingDocumentsForTurnover = createAction(`${ActionPrefix} NavigateToAccountingDocumentsForTurnover`, props<{ operatorDate: IFilterOperator, range: Date[], operatorADTypes: IFilterOperator, types: AccountingDocumentTypeCode[]}>());
export const NavigateToAccountingDocumentsForOutstanding = createAction(`${ActionPrefix} NavigateToAccountingDocumentsForOutstanding`, props<{ mode: OutstandingMode, dunningLevel: DunningLevel, range: Date[]}>());
export const NavigateToAccountingDocumentsByAge = createAction(`${ActionPrefix} NavigateToAccountingDocumentsByAge`, props<{ operator: IFilterOperator, range: Date[] }>());
export const NavigateToSuperDebtorsByCountry = createAction(`${ActionPrefix} NavigateToSuperDebtorsByCountry`, props<{ operator: IFilterOperator, countries: CountryCodeType[] }>());
export const NavigateToSuperDebtorsByScoring = createAction(`${ActionPrefix} NavigateToSuperDebtorsByScoring`, props<{ operator: IFilterOperator, range: number[] }>());

export const outstandingAmountByDunningLevelsActions = createEntityActions<IHomeOutstandingByDunningLevelChartData>(ActionPrefix, 'Outstanding Amount By DunningLevel');
export const {
	LoadEntityRequest: LoadOutstandingAmountByDunningLevelsRequest,
	LoadEntitySuccess: LoadOutstandingAmountByDunningLevelsSuccess,
	LoadEntityFailure: LoadOutstandingAmountByDunningLevelsFailure,
	ExportEntityRequest: ExportOutstandingAmountByDunningLevelsRequest,
	ExportEntitySuccess: ExportOutstandingAmountByDunningLevelsSuccess,
	ExportEntityFailure: ExportOutstandingAmountByDunningLevelsFailure,
} = outstandingAmountByDunningLevelsActions;

export const superDebtorScoringsActions = createEntityActions<IHomeScoringSlices>(ActionPrefix, 'Debtor Scorings');
export const {
	LoadEntityRequest: LoadSuperDebtorScoringsRequest,
	LoadEntitySuccess: LoadSuperDebtorScoringsSuccess,
	LoadEntityFailure: LoadSuperDebtorScoringsFailure,
} = superDebtorScoringsActions;

export const turnoverDataActions = createEntityActions<IHomeTurnoverChartData>(ActionPrefix, 'Turnover Chart Data');
export const {
	LoadEntityRequest: LoadTurnoverChartDataRequest,
	LoadEntitySuccess: LoadTurnoverChartDataSuccess,
	LoadEntityFailure: LoadTurnoverChartDataFailure,
	ExportEntityRequest: ExportTurnoverChartDataRequest,
	ExportEntitySuccess: ExportTurnoverChartDataSuccess,
	ExportEntityFailure: ExportTurnoverChartDataFailure,
} = turnoverDataActions;

export const averageOverdueDataActions = createEntityActions<IHomeAverageOverdueChartData>(ActionPrefix, 'AverageOverdue Chart Data');
export const {
	LoadEntityRequest: LoadAverageOverdueDataRequest,
	LoadEntitySuccess: LoadAverageOverdueDataSuccess,
	LoadEntityFailure: LoadAverageOverdueDataFailure,
	ExportEntityRequest: ExportAverageOverdueDataRequest,
	ExportEntitySuccess: ExportAverageOverdueDataSuccess,
	ExportEntityFailure: ExportAverageOverdueDataFailure,
} = averageOverdueDataActions;

export const estimatedCashingKpisActions = {
	...createEntityActions<IHomeEstimatedCashingKpis>(ActionPrefix, 'Home EstimatedCashing KPI'),
	LoadEntityRequest: createAction(`[${ActionPrefix}] Home EstimatedCashing KPI Request`, props<{ mode: SuperCustomerEstimatedCashingMode }>())
};
export const {
	LoadEntityRequest: LoadHomeEstimatedCashingKpisRequest,
	LoadEntitySuccess: LoadHomeEstimatedCashingKpisSuccess,
	LoadEntityFailure: LoadHomeEstimatedCashingKpisFailure,
	ExportEntityRequest: ExportHomeEstimatedCashingKpisRequest,
	ExportEntitySuccess: ExportHomeEstimatedCashingKpisSuccess,
	ExportEntityFailure: ExportHomeEstimatedCashingKpisFailure,
} = estimatedCashingKpisActions;

export const topOutstandingAmountActions = {
	...createListActions<TopOutstandingList, TopOutstandingSortProp, IFilter>(ActionPrefix, 'Home Top Outstanding Amount'),
	LoadListRequest: createTypedAction(`[${ActionPrefix}] Home Top Outstanding Amount Request`, ListActionType.LoadListRequest, props<{ mode: OutstandingMode }>())
};
export const {
	LoadListRequest: LoadHomeTopOutstandingAmountRequest,
	LoadListSuccess: LoadHomeTopOutstandingAmountSuccess,
	LoadListFailure: LoadHomeTopOutstandingAmountFailure,
} = topOutstandingAmountActions;

export const topDebtorsWithClaimsActions = createEntityActions<ITopDebtorsWithClaims>(ActionPrefix, 'Home Top Claims Debtors');
export const {
	LoadEntityRequest: LoadHomeTopDebtorsWithClaimsRequest,
	LoadEntitySuccess: LoadHomeTopDebtorsWithClaimsSuccess,
	LoadEntityFailure: LoadHomeTopDebtorsWithClaimsFailure,
	ExportEntityRequest: ExportHomeTopDebtorsWithClaimsRequest,
	ExportEntitySuccess: ExportHomeTopDebtorsWithClaimsSuccess,
	ExportEntityFailure: ExportHomeTopDebtorsWithClaimsFailure,
} = topDebtorsWithClaimsActions;

export const ageingBalanceActions = {
	...createEntityActions<IHomeAgeingBalanceChartData>(ActionPrefix, 'Home AgeingBalance'),
	ExportEntityRequest: createTypedAction(`[${ActionPrefix}] Home Export AgeingBalance Request`, EntityActionType.ExportEntityRequest, props<{ mode: CountMode }>()),
	ExportEntitySuccess: createTypedAction(`[${ActionPrefix}] Home Export AgeingBalance Success`, EntityActionType.ExportEntitySuccess, props<{ mode: CountMode }>()),
}
export const {
	LoadEntityRequest: LoadAgeingBalanceRequest,
	LoadEntitySuccess: LoadAgeingBalanceSuccess,
	LoadEntityFailure: LoadAgeingBalanceFailure,
	ExportEntityRequest: ExportAgeingBalanceRequest,
	ExportEntitySuccess: ExportAgeingBalanceSuccess,
	ExportEntityFailure: ExportAgeingBalanceFailure,
} = ageingBalanceActions;

export const ageingBalanceDetailedActions = createEntityActions<void>(ActionPrefix, 'Home Export DetailedAgeingBalance');
export const {
	ExportEntityRequest: ExportDetailedAgeingBalanceRequest,
	ExportEntitySuccess: ExportDetailedAgeingBalanceSuccess,
	ExportEntityFailure: ExportDetailedAgeingBalanceFailure,
} = ageingBalanceDetailedActions;

export const dsoMonitoringActions = createEntityActions<DsoModel[]>(ActionPrefix, 'DSO Monitoring');
export const {
	LoadEntityRequest: LoadDsoMonitoringRequest,
	LoadEntitySuccess: LoadDsoMonitoringSuccess,
	LoadEntityFailure: LoadDsoMonitoringFailure,
	ExportEntityRequest: ExportDsoMonitoringRequest,
	ExportEntitySuccess: ExportDsoMonitoringSuccess,
	ExportEntityFailure: ExportDsoMonitoringFailure,
} = dsoMonitoringActions;


export const turnoverWorldMapDataActions = createEntityActions<ICountryMarker[]>(ActionPrefix, 'Turnover World Map Data');
export const {
	LoadEntityRequest: LoadTurnoverWorldMapDataRequest,
	LoadEntitySuccess: LoadTurnoverWorldMapDataSuccess,
	LoadEntityFailure: LoadTurnoverWorldMapDataFailure,
	ExportEntityRequest: ExportTurnoverWorldMapDataRequest,
	ExportEntitySuccess: ExportTurnoverWorldMapDataSuccess,
	ExportEntityFailure: ExportTurnoverWorldMapDataFailure,
} = turnoverWorldMapDataActions;
