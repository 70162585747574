import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { OutstandingMode } from 'apps/federation/src/app/shared-module/enums';
import { saveAs } from 'file-saver';
import { SuperCustomersListFilterSpecService, SuperCustomersListService } from 'apps/federation/src/app/customers-module/services';
import { SuperCustomerEstimatedCashingMode } from 'apps/federation/src/app/customers-module/models';
import { AccountingListFilterSpecService } from 'apps/federation/src/app/accounting-module/services';
import * as RoutesDefinitions from 'apps/federation/src/app/routes-definitions';
import { IFilterOperator } from '@aston/foundation';
import { HomeExportsService, HomeService } from 'apps/federation/src/app/home-module/services';

import { SuperCustomersStoreActions } from '../super-customers-store';
import { AccountingStoreActions } from '../accounting-store';
import { AppStoreActions } from '../app-store';

import * as featureActions from './actions';

@Injectable({providedIn: 'root'})
export class HomeActionsStoreEffects {
	constructor(
		private actions$: Actions,
		protected translateService: TranslateService,
		private homeActionService: HomeService,
		private homeExportsService: HomeExportsService,
		private superCustomersService: SuperCustomersListService,
		private accFilters: AccountingListFilterSpecService,
		private sdFilters: SuperCustomersListFilterSpecService,
	) { }

	init$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.InitHomepage, featureActions.InitDashboards),
		switchMap(_ => this.superCustomersService.noSuperCustomersPresent().pipe(map(isEmpty => ({ isEmpty })))),
		switchMap(({ isEmpty }) => [
			featureActions.UpdateIsEmpty({ isEmpty }),
		])
	));

	loadOutstandingAmountByDunningLevelsEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadOutstandingAmountByDunningLevelsRequest),
		switchMap(_ => this.homeActionService.getOutstandingAmountByDunningLevelsKpis().pipe(
			map(entity => featureActions.LoadOutstandingAmountByDunningLevelsSuccess({ entity })),
			catchError(error => of(featureActions.LoadOutstandingAmountByDunningLevelsFailure({ error })))
		))
	));

	exportOutstandingAmountByDunningLevelsEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ExportOutstandingAmountByDunningLevelsRequest),
		switchMap(_ => this.homeExportsService.exportOutstandingAmountByDunningLevelsKpis().pipe(
			map(response => {
				saveAs(response.blob, response.fileName);
				return featureActions.ExportOutstandingAmountByDunningLevelsSuccess({});
			}),
			catchError(error => of(
				featureActions.ExportOutstandingAmountByDunningLevelsFailure({ error }),
				error.is('AlreadyRunning') ? AppStoreActions.ToastError('Errors.OperationRequestAlreadyRunning') : AppStoreActions.Noop(),
			))
		))
	));

	loadAverageOverdueDataRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAverageOverdueDataRequest),
		switchMap(_ => this.homeActionService.getAverageOverdueKpis().pipe(
			map(entity => featureActions.LoadAverageOverdueDataSuccess({ entity })),
			catchError(error => of(featureActions.LoadAverageOverdueDataFailure({ error })))
		))
	));

	exportAverageOverdueDataRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ExportAverageOverdueDataRequest),
		switchMap(_ => this.homeExportsService.exportAverageOverdueKpis().pipe(
			map(response => {
				saveAs(response.blob, response.fileName);
				return featureActions.ExportAverageOverdueDataSuccess({});
			}),
			catchError(error => of(featureActions.ExportAverageOverdueDataFailure({ error })))
		))
	));

	loadTopOutstandingKpisRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadHomeTopOutstandingAmountRequest),
		switchMap(action => this.homeActionService.getHomeTopOutstandingClientsKpis(action.mode).pipe(
			map(list => featureActions.LoadHomeTopOutstandingAmountSuccess({ list })),
			catchError(error => of(featureActions.LoadHomeTopOutstandingAmountFailure({ error })))
		))
	));

	updateHomeScopeEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.InitHomepage, featureActions.InitDashboards),
		debounceTime(500),
		switchMap(_ => [
			featureActions.LoadHomeTopOutstandingAmountRequest({ mode: OutstandingMode.Total }),
			featureActions.LoadOutstandingAmountByDunningLevelsRequest(),
			featureActions.LoadAgeingBalanceRequest(),
			featureActions.LoadHomeEstimatedCashingKpisRequest({ mode: SuperCustomerEstimatedCashingMode.Estimated }),
			featureActions.LoadDsoMonitoringRequest(),
			featureActions.LoadSuperDebtorScoringsRequest(),
			featureActions.LoadTurnoverChartDataRequest(),
			featureActions.LoadHomeTopDebtorsWithClaimsRequest(),
			featureActions.LoadAverageOverdueDataRequest(),
			featureActions.LoadTurnoverWorldMapDataRequest(),
			featureActions.ShouldBeReloaded({scope: false})
		])
	));

	loadAgeingBalanceRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAgeingBalanceRequest),
		switchMap(_ => this.homeActionService.getAgeingBalanceKpis().pipe(
			map(entity => featureActions.LoadAgeingBalanceSuccess({ entity })),
			catchError(error => of(featureActions.LoadAgeingBalanceFailure({ error })))
		))
	));

	exportAgeingBalanceRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ExportAgeingBalanceRequest),
		switchMap(action => this.homeExportsService.exportAgeingBalanceKpis().pipe(
			map(response => {
				saveAs(response.blob, response.fileName);
				return featureActions.ExportAgeingBalanceSuccess({ mode: action.mode });
			}),
			catchError(error => of(
				featureActions.ExportAgeingBalanceFailure({ error }),
				error.is('AlreadyRunning') ? AppStoreActions.ToastError('Errors.OperationRequestAlreadyRunning') : AppStoreActions.Noop(),
			))
		))
	));

	exportDetailedAgeingBalanceRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ExportDetailedAgeingBalanceRequest),
		switchMap(_ => this.homeExportsService.exportDetailedAgeingBalanceKpis().pipe(
			map(response => {
				saveAs(response.blob, response.fileName);
				return featureActions.ExportDetailedAgeingBalanceSuccess({});
			}),
			catchError(error => of(
				featureActions.ExportDetailedAgeingBalanceFailure({ error }),
				error.is('AlreadyRunning') ? AppStoreActions.ToastError('Errors.OperationRequestAlreadyRunning') : AppStoreActions.Noop(),
			))
		))
	));

	loadHomeTopDebtorsWithClaimsRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadHomeTopDebtorsWithClaimsRequest),
		switchMap(_ => this.homeActionService.getTopDebtorsWithClaimsKpis().pipe(
			map(entity => featureActions.LoadHomeTopDebtorsWithClaimsSuccess({ entity })),
			catchError(error => of(featureActions.LoadHomeTopDebtorsWithClaimsFailure({ error })))
		))
	));

	exportHomeTopDebtorsWithClaimsRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ExportHomeTopDebtorsWithClaimsRequest),
		switchMap(_ => this.homeExportsService.exportTopDebtorsWithClaimsKpis().pipe(
			map(response => {
				saveAs(response.blob, response.fileName);
				return featureActions.ExportHomeTopDebtorsWithClaimsSuccess({})
			}),
			catchError(error => of(featureActions.ExportHomeTopDebtorsWithClaimsFailure({ error })))
		))
	));

	loadSuperDebtorScoringsRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSuperDebtorScoringsRequest),
		switchMap(_ => this.homeActionService.getSuperCustomerScoringsKpis().pipe(
			map(entity => featureActions.LoadSuperDebtorScoringsSuccess({ entity })),
			catchError(error => of(featureActions.LoadSuperDebtorScoringsFailure({ error })))
		))
	));

	loadTurnoverChartDataRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadTurnoverChartDataRequest),
		switchMap(_ => this.homeActionService.getTurnoverChartDataKpis().pipe(
			map(entity => featureActions.LoadTurnoverChartDataSuccess({ entity })),
			catchError(error => of(featureActions.LoadTurnoverChartDataFailure({ error })))
		))
	));

	exportTurnoverChartDataRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ExportTurnoverChartDataRequest),
		switchMap(_ => this.homeExportsService.exportTurnoverChartDataKpis().pipe(
			map(response => {
				saveAs(response.blob, response.fileName);
				return featureActions.ExportTurnoverChartDataSuccess({});
			}),
			catchError(error => of(featureActions.ExportTurnoverChartDataFailure({ error })))
		))
	));

	loadHomeEstimatedCashingKpisRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadHomeEstimatedCashingKpisRequest),
		switchMap(action => this.homeActionService.getEstimatedCashingKpis(action.mode).pipe(
			map(entity => featureActions.LoadHomeEstimatedCashingKpisSuccess({ entity })),
			catchError(error => of(featureActions.LoadHomeEstimatedCashingKpisFailure({ error })))
		))
	));


	exportEstimatedCashingRequest$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ExportHomeEstimatedCashingKpisRequest),
		switchMap(_ => this.homeExportsService.exportEstimatedCashingKpis().pipe(
			map(response => {
				saveAs(response.blob, response.fileName);
				return featureActions.ExportHomeEstimatedCashingKpisSuccess(null);
			}),
			catchError(error => of(
				featureActions.ExportHomeEstimatedCashingKpisFailure({ error }),
				AppStoreActions.ToastError('Errors.RetryableError')
			))
		))
	));

	LoadDsoMonitoringRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadDsoMonitoringRequest),
		switchMap(_ => this.homeActionService.getDsoMonitoringKpis().pipe(
			map(entity => featureActions.LoadDsoMonitoringSuccess({ entity })),
			catchError(error => of(featureActions.LoadDsoMonitoringFailure({ error })))
		))
	));

	exportDsoMonitoringEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ExportDsoMonitoringRequest),
		switchMap(_ => this.homeExportsService.exportDsoMonitoringKpis().pipe(
			map(response => {
				saveAs(response.blob, response.fileName);
				return featureActions.ExportDsoMonitoringSuccess({});
			}),
			catchError(error => of(
				featureActions.ExportDsoMonitoringFailure({ error }),
				error.is('AlreadyRunning') ? AppStoreActions.ToastError('Errors.OperationRequestAlreadyRunning') : AppStoreActions.Noop(),
			))
		))
	));

	loadTurnoverWorldMapDataRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadTurnoverWorldMapDataRequest),
		switchMap(_ => this.homeActionService.getTurnoverWorldMapKpis().pipe(
			switchMap(entity => [
				featureActions.LoadTurnoverWorldMapDataSuccess({ entity }),
			]),
			catchError(error => of(
				featureActions.LoadTurnoverWorldMapDataFailure({ error }),
			))
		))
	));

	exportTurnoverWorldMapDataRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ExportTurnoverWorldMapDataRequest),
		switchMap(_ => this.homeExportsService.exportTurnoverWorldMapKpis().pipe(
			map(response => {
				saveAs(response.blob, response.fileName);
				return featureActions.ExportTurnoverWorldMapDataSuccess({});
			}),
			catchError(error => of(
				featureActions.ExportTurnoverWorldMapDataFailure({ error }),
				error.is('AlreadyRunning') ? AppStoreActions.ToastError('Errors.OperationRequestAlreadyRunning') : AppStoreActions.Noop(),
			))
		))
	));

	navigateToAccDocumentsByDunningLevels$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.NavigateToAccountingDocumentsByDunningLevels),
		switchMap(action => [
			AccountingStoreActions.ResetAccountingFilters(),
			AccountingStoreActions.AddAccountingFilters({
				filters: [
					this.accFilters.onlyDocumentsWithBalance(),
					this.accFilters.getDunningLevelFieldFilter(...action.dunningLevels),
					action.onlyExpired ? this.accFilters.byDueDate(IFilterOperator.LessThan, [new Date()]) : null,
				].filter(Boolean)
			}),
			AppStoreActions.Navigate({ to: [RoutesDefinitions.getAccountingListFullPath()] })
		])
	));

	navigateToAccDocumentsByClaimTypes$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.NavigateToAccountingDocumentsByClaimTypes),
		switchMap(action => this.accFilters.categories$.pipe(
			switchMap(filters => [
			AccountingStoreActions.ResetAccountingFilters(),
			AccountingStoreActions.AddAccountingFilters({
				filters: [
					this.accFilters.onlyDocumentsWithBalance(),
					this.accFilters.getClaimTypesFieldFilter(action.claimTypes, filters.accounting.value.items.find(f => f.field.value === 'claimTypeId')),
				]
			}),
			AppStoreActions.Navigate({ to: [RoutesDefinitions.getAccountingListFullPath()] })
		]))
	)));

	navigateToAccDocumentsByAge$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.NavigateToAccountingDocumentsByAge),
		switchMap(action => [
			AccountingStoreActions.ResetAccountingFilters(),
			AccountingStoreActions.AddAccountingFilters({
				filters: [
					this.accFilters.onlyDocumentsWithBalance(),
					this.accFilters.byDueDate(action.operator, action.range),
				]
			}),
			AppStoreActions.Navigate({ to: [RoutesDefinitions.getAccountingListFullPath()] })
		])
	));

	navigateToSuperDebtorsByCountry$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.NavigateToSuperDebtorsByCountry),
		switchMap(action => [
			SuperCustomersStoreActions.ResetSuperCustomersFilters(),
			SuperCustomersStoreActions.AddSuperCustomersFilters({
				filters: [
					this.sdFilters.byCountries(action.operator, action.countries),
				]
			}),
			AppStoreActions.Navigate({ to: [RoutesDefinitions.getSuperCustomersListFullPath()] })
		])
	));

	NavigateToSuperDebtorsByScoring$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.NavigateToSuperDebtorsByScoring),
		switchMap(action => [
			SuperCustomersStoreActions.ResetSuperCustomersFilters(),
			SuperCustomersStoreActions.AddSuperCustomersFilters({
				filters: [
					this.sdFilters.byRisks(action.operator, action.range),
				]
			}),
			AppStoreActions.Navigate({ to: [RoutesDefinitions.getSuperCustomersListFullPath()] })
		])
	));

	navigateToAccDocumentsForTurnover$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.NavigateToAccountingDocumentsForTurnover),
		switchMap(action => [
			AccountingStoreActions.ResetAccountingFilters(),
			AccountingStoreActions.SetShowCompleted({ showCompleted: true }),
			AccountingStoreActions.AddAccountingFilters({
				filters: [
					this.accFilters.byAccountingDate(action.operatorDate, action.range),
					this.accFilters.byAccountingDocumentType(action.operatorADTypes, action.types),
				]
			}),
			AppStoreActions.Navigate({ to: [RoutesDefinitions.getAccountingListFullPath()] })
		])
	));

	navigateToAccDocumentsForOutstanding$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.NavigateToAccountingDocumentsForOutstanding),
		switchMap(action => {
			const filters = [
				this.accFilters.getDunningLevelFieldFilter(action.dunningLevel),
			];

			if (action.mode === OutstandingMode.Expired) {
				filters.push(this.accFilters.byDueDate(IFilterOperator.LessThan, action.range))
			}

			return [
				AccountingStoreActions.ResetAccountingFilters(),
				AccountingStoreActions.AddAccountingFilters({	filters }),
				AppStoreActions.Navigate({ to: [RoutesDefinitions.getAccountingListFullPath()] })
		]})
	));
}
