import { ISuperCustomerKpi } from 'apps/federation/src/app/customers-module/models/super-customer';
import { createFeatureSelector,	createSelector,	MemoizedSelector } from '@ngrx/store';

import { createEntitySliceSelectors, createListSliceSelectors } from '../functions';

import { ISuperCustomerPageState } from './state';
import { FeatureName } from './feature';



export const selectState: MemoizedSelector<object, ISuperCustomerPageState> = createFeatureSelector<ISuperCustomerPageState>(FeatureName);

const getSuperCustomerKpi = (state: ISuperCustomerPageState): ISuperCustomerKpi => state.superCustomerIdentity.entity && state.superCustomerIdentity.entity.kpi;
const getIsLoading = (state: ISuperCustomerPageState): boolean => state.isLoading;
const getError = (state: ISuperCustomerPageState): any => state.error;

export const selectSuperCustomerKpi = createSelector(selectState,	getSuperCustomerKpi);
export const selectIsLoading = createSelector(selectState, getIsLoading);
export const selectError = createSelector(selectState, getError);

export const selectSuperCustomerId = createSelector(selectState, state => state.superCustomerId);

export const {
	selectEntity: selectSuperCustomerIdentity
} = createEntitySliceSelectors(createSelector(selectState, state => state.superCustomerIdentity));

export const {
	selectSlice: selectAccountingSlice,
	selectList: selectAccounting,
	selectFilters: selectAccountingFilters,
	selectError: selectAccountingError,
	selectIsLoading: selectAccountingIsLoading,
	selectPaging: selectAccountingPaging,
	selectIsExporting: selectAccountingIsExporting,
} = createListSliceSelectors(createSelector(selectState, state => state.superCustomerAccountingDocuments));

export const {
	selectSlice: selectAttachedDebtorsSlice,
	selectList: selectAttachedDebtors,
	selectFilters: selectAttachedDebtorsFilters,
	selectError: selectAttachedDebtorsError,
	selectIsLoading: selectAttachedDebtorsIsLoading,
	selectPaging: selectAttachedDebtorsPaging
} = createListSliceSelectors(createSelector(selectState, state => state.attachedCustomers));

export const {
	selectSlice: selectAllAttachedDebtorsSlice,
	selectList: selectAllAttachedDebtors,
	selectPaging: selectAllAttachedDebtorsPaging,
} = createListSliceSelectors(createSelector(selectState, state => state.allAttachedCustomers));
