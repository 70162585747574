
import { ISuperCustomerIdentity, ICustomersList } from 'apps/federation/src/app/customers-module/models';
import { IAccountingItemList, IAccountingListFilter } from 'apps/federation/src/app/accounting-module/models';
import { AccountingListSortProp } from 'apps/federation/src/app/accounting-module/enums';
import { CustomersListSortProp } from 'apps/federation/src/app/customers-module/enums';
import { IFilter } from '@aston/foundation';
import { AppConstants } from 'apps/federation/src/app/app.constants';

import { createEntitySlice, createListSlice, IStoreEntityStateSlice, IStoreListStateSlice } from '../functions';

export interface ISuperCustomerPageState {
	isLoading: boolean;
	error: Error;

	superCustomerId: number;
	superCustomerIdentity: IStoreEntityStateSlice<ISuperCustomerIdentity>;
	superCustomerAccountingDocuments: IStoreListStateSlice<IAccountingItemList, AccountingListSortProp, IAccountingListFilter>;
	showCompleted: boolean;
	accountingDocumentsAllSelected: boolean;
	accountingDocumentsIdsList: IStoreEntityStateSlice<number[]>;
	accountingDocumentsSelectedIds: number[];
	attachedCustomers: IStoreListStateSlice<ICustomersList, CustomersListSortProp, IFilter>;
	allAttachedCustomers: IStoreListStateSlice<ICustomersList, CustomersListSortProp, IFilter>;
}

export const initialState: ISuperCustomerPageState = {
	// Base
	superCustomerId: null,
	isLoading: false,
	error: null,

	superCustomerIdentity: createEntitySlice(),

	// Accounting
	superCustomerAccountingDocuments: createListSlice({prop: AccountingListSortProp.DueDate, isDesc: false}),
	showCompleted: false,

	accountingDocumentsAllSelected: false,
	accountingDocumentsIdsList: createEntitySlice({entity: []}),
	accountingDocumentsSelectedIds: [],

	// Attached customers
	attachedCustomers: createListSlice({prop: CustomersListSortProp.TOTAL_OUTSTANDING_AMOUNT, isDesc: false}),
	allAttachedCustomers: createListSlice({prop: CustomersListSortProp.TOTAL_OUTSTANDING_AMOUNT, isDesc: false}, AppConstants.LIST_PAGE_SIZE_IGNORED),
};
