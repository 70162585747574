import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom, debounceTime, take, mapTo } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

import { SuperCustomerPageStoreSelectors } from '../super-customer-page-store';
import { AppStoreActions } from '../app-store';
import { CorrelationParams, aggregate } from '../models/correlated-actions.model';
import * as appActions from '../app-store/actions';
import { SuperCustomersListService } from '../../customers-module/services';

import { getSuperCustomerById } from './helpers';
import * as featureSelectors from './selectors';
import * as featureActions from './actions';



@Injectable({providedIn: 'root'})
export class SuperCustomersStoreEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		private superCustomersService: SuperCustomersListService,
		protected translateService: TranslateService
	) { }

	initEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.InitRequest),
		withLatestFrom(this.store),
		map(([action]) => {
			const correlationParams: CorrelationParams = action.correlationParams;
			correlationParams.parentActionType = action.type;
			return featureActions.LoadSuperCustomersRequest(correlationParams);
		}),
		catchError(error => of(featureActions.InitFailure({ error })))
	));

	afterInitEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AfterInitRequest),
		// Wait for the next InitRequest to happen
		switchMap(action => this.actions$.pipe(ofType(featureActions.InitRequest), take(1), mapTo(action))),
		// Then dispatch given actions
		switchMap(action => action.dispatch),
	));

	initSuccessEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.InitRequest),
		aggregate(
			this.actions$.pipe(ofType(featureActions.LoadSuperCustomersSuccess)),
			this.actions$.pipe(ofType(featureActions.InitFailure)),
		),
		map(_ => featureActions.InitSuccess()),
		catchError(error => of(featureActions.InitFailure({ error })))
	));


	loadRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSuperCustomersRequest),
		withLatestFrom(this.store.select(featureSelectors.selectListSlice)),
		debounceTime(500),
		switchMap(([action, listSlice]) => {
			const { filters, paging } = listSlice;

			return this.superCustomersService.getSuperCustomers({ ...paging, filters }).pipe(
				map(list => featureActions.LoadSuperCustomersSuccess({ list, correlationParams: action.correlationParams })),
				catchError(error =>
					of(featureActions.LoadSuperCustomersFailure({ error }))
				)
			);
		})
	));

	exportRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ExportSuperCustomersRequest),
		withLatestFrom(this.store.select(featureSelectors.selectListSlice)),
		debounceTime(500),
		switchMap(([action, listSlice]) => {
			const { filters, paging } = listSlice;

			return this.superCustomersService.exportSuperCustomers({ ...paging, filters }).pipe(
				map(response => {
					saveAs(response.blob, response.fileName);
					return featureActions.ExportSuperCustomersSuccess({ correlationParams: action.correlationParams });
				}),
				catchError(error => of(
					featureActions.ExportSuperCustomersFailure({ error }),
					AppStoreActions.ToastError('Errors.RetryableError')
				))
			);
		})
	));

	updateSettingsAction$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateSuperCustomersSettings),
		map(_ => featureActions.LoadSuperCustomersRequest())
	));

	updateFilterEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AddSuperCustomersFilters, featureActions.RemoveSuperCustomersFilters),
		map(_ => featureActions.LoadSuperCustomersRequest())
	));

	loadSuccessEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSuperCustomersSuccess),
		map(_ => appActions.StartScrollToTop())
	));

	updateDebtorsSettings$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateCustomersSettings),
		map(action => featureActions.LoadCustomersRequest({ id: action.id }))
	));

	loadAttachedDebtorsEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAttachedCustomersRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectState),
			this.store.select(SuperCustomerPageStoreSelectors.selectAttachedDebtorsFilters)),
		switchMap(([action, state, filters]) => {
				const superCustomer = getSuperCustomerById(state, action.id);
				const paging = superCustomer.attachedDebtorsListPaging;
				return this.superCustomersService.getAttachedDebtorsBySuperCustomerId(superCustomer.id, {...paging, filters }).pipe(
					map(attachedDebtors => featureActions.LoadAttachedCustomersSuccess({ id: action.id, attachedCustomers: attachedDebtors })),
					catchError(error =>
						of(featureActions.LoadAttachedCustomersFailure({ id: action.id, error }))
					)
				);
			}
		)
	));

	updateAttachedDebtorsSettings$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateAttachedCustomersSettings),
		map(action => featureActions.LoadAttachedCustomersRequest({ id: action.id }))
	));
}
