import { IListState } from '@aston/foundation';
import { CustomersListSortProp } from 'apps/federation/src/app/customers-module/enums';
import { createFeatureSelector,	createSelector,	MemoizedSelector } from '@ngrx/store';

import { ISuperCustomerItem, ICustomersList } from '../../customers-module/models';
import { createListSliceSelectors } from '../functions';

import { ISuperCustomersState } from './state';
import { getSuperCustomerById } from './helpers';
import { FeatureName } from './feature';



export const selectState: MemoizedSelector<object, ISuperCustomersState> = createFeatureSelector<ISuperCustomersState>(FeatureName);

const getIsLoading = (state: ISuperCustomersState): boolean => state.isLoading;
export const selectIsLoading = createSelector(selectState, getIsLoading);
const getError = (state: ISuperCustomersState): string => state.error;
export const selectError = createSelector(selectState, getError);

export const {
	selectSlice: selectListSlice,
	selectList: selectList,
	selectFilters: selectListFilters,
	selectError: selectListError,
	selectIsLoading: selectListIsLoading,
	selectPaging: selectListPaging,
	selectIsExporting: selectListIsExporting
} = createListSliceSelectors(createSelector(selectState, state => state.list));

const getSuperCustomer = (state: ISuperCustomersState, id: number): ISuperCustomerItem => getSuperCustomerById(state, id);
const getSuperCustomerDebtorsList = (state: ISuperCustomersState, id: number): ICustomersList => getSuperCustomer(state, id) ? getSuperCustomer(state, id).debtorsList : null;
const getSuperCustomerDebtorsListIsOpened = (state: ISuperCustomersState, id: number): boolean => getSuperCustomer(state, id) ?  getSuperCustomer(state, id).isDebtorsListOpened : null;
const getSuperCustomerDebtorsListIsLoading = (state: ISuperCustomersState, id: number): boolean => getSuperCustomer(state, id) ?  getSuperCustomer(state, id).isDebtorsListLoading : null;
const getSuperCustomerDebtorsListError = (state: ISuperCustomersState, id: number): string => getSuperCustomer(state, id) ? getSuperCustomer(state, id).debtorsListError : null;
const getSuperCustomerDebtorsListState = (state: ISuperCustomersState, id: number): IListState<CustomersListSortProp> => getSuperCustomer(state, id) ?  getSuperCustomer(state, id).debtorsListPaging : null;

const getSuperCustomerAttachedDebtorsList = (state: ISuperCustomersState, id: number): ICustomersList => getSuperCustomer(state, id) ? getSuperCustomer(state, id).attachedDebtorsList : null;
const getSuperCustomerAttachedDebtorsListIsOpened = (state: ISuperCustomersState, id: number): boolean => getSuperCustomer(state, id) ?  getSuperCustomer(state, id).isAttachedDebtorsListOpened : null;
const getSuperCustomerAttachedDebtorsListIsLoading = (state: ISuperCustomersState, id: number): boolean => getSuperCustomer(state, id) ?  getSuperCustomer(state, id).isAttachedDebtorsListLoading : null;
const getSuperCustomerAttachedDebtorsListError = (state: ISuperCustomersState, id: number): string => getSuperCustomer(state, id) ? getSuperCustomer(state, id).attachedDebtorsListError : null;
const getSuperCustomerAttachedDebtorsListState = (state: ISuperCustomersState, id: number): IListState<CustomersListSortProp> => getSuperCustomer(state, id) ?  getSuperCustomer(state, id).attachedDebtorsListPaging : null;

export const selectSuperDebtorDebtorsList = (id: number) => createSelector(selectState, state => getSuperCustomerDebtorsList(state, id));
export const selectSuperDebtorDebtorsListIsOpened = (id: number) => createSelector(selectState, state => getSuperCustomerDebtorsListIsOpened(state, id));
export const selectSuperDebtorDebtorsListIsLoading = (id: number) => createSelector(selectState, state => getSuperCustomerDebtorsListIsLoading(state, id));
export const selectSuperDebtorDebtorsListError = (id: number) => createSelector(selectState, state => getSuperCustomerDebtorsListError(state, id));
export const selectSuperDebtorDebtorsListState = (id: number) => createSelector(selectState, state => getSuperCustomerDebtorsListState(state, id));

export const selectSuperDebtorAttachedDebtorsList = (id: number) => createSelector(selectState, state => getSuperCustomerAttachedDebtorsList(state, id));
export const selectSuperDebtorAttachedDebtorsListIsOpened = (id: number) => createSelector(selectState, state => getSuperCustomerAttachedDebtorsListIsOpened(state, id));
export const selectSuperDebtorAttachedDebtorsListIsLoading = (id: number) => createSelector(selectState, state => getSuperCustomerAttachedDebtorsListIsLoading(state, id));
export const selectSuperDebtorAttachedDebtorsListError = (id: number) => createSelector(selectState, state => getSuperCustomerAttachedDebtorsListError(state, id));
export const selectSuperDebtorAttachedDebtorsListState = (id: number) => createSelector(selectState, state => getSuperCustomerAttachedDebtorsListState(state, id));
