import { DateOnly, DunningLevel } from '@aston/foundation';
import { IHomePieData } from 'apps/federation/src/app/home-module/models';

export interface ISuperCustomerDSOChartItem {
    monthDate: DateOnly;
    dso: number;
    bpDso: number;
}

export type ISuperCustomerDSOChartData = ISuperCustomerDSOChartItem[];

export interface ISuperCustomerTurnoverChartItem {
    monthDate: DateOnly;
    totalRevenues: number;
}

export type ISuperCustomerTurnoverChartData = ISuperCustomerTurnoverChartItem[];

export interface ISuperCustomerScoringChartItem {
    monthDate: DateOnly;
    scoring: number;
}

export type ISuperCustomerScoringChartData = ISuperCustomerScoringChartItem[];

export interface IOutstandingDunningLevel {
    dunningLevel: DunningLevel;
    totalOutstandingAmount: number;
    expiredOutstandingAmount: number;
}

export interface IOutstandingMonth {
    monthDate: DateOnly;
    outstandingAmountDunningLevels: IOutstandingDunningLevel[];
}

export type ISuperCustomerOutstandingByDunningLevelChartData = IOutstandingMonth[];

export type ISuperCustomerOutstandingPieByDunningLevelChartData = IHomePieData;

export type TotalsByMonth = { [key: string]: number };

export interface ISuperCustomerAgeingBalanceSlice {
    label: string;
    position: number;
    totalOutstandingAmount: number;
    accountingDocumentCount: number;
}

export type ISuperCustomerAgeingBalanceChartData = ISuperCustomerAgeingBalanceSlice[];

export interface ISuperCustomerAverageOverdueItem {
    monthDate: DateOnly;
    averageOverdueDays: number;
    averageOverdueAmount: number;
}

export type ISuperCustomerAverageOverdueChartData = ISuperCustomerAverageOverdueItem[];

export interface SuperCustomerAverageLitigationDelayItem {
    claimName: string,
    accountingDocumentCount: number;
    totalAmount: number;
}

export interface ISuperCustomerAverageLitigationDelayChartData {
    averageDelayClosingLitigation: number;
    claimInfos: SuperCustomerAverageLitigationDelayItem[];
}

export interface ISuperCustomerEstimatedCashingChartDataItem {
    amount: number;
    dunningLevel: DunningLevel;
}

export interface ISuperCustomerEstimatedCashingChartDataSlice {
    range: number;
    infos: ISuperCustomerEstimatedCashingChartDataItem[];
}

export enum SuperCustomerEstimatedCashingMode {
    Estimated = 'estimated',
    Delayed = 'delayed',
}

export type ISuperCustomerEstimatedCashingChartData = ISuperCustomerEstimatedCashingChartDataSlice[];
