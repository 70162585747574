
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { FeatureName } from './feature';
import { superCustomerPageReducer } from './reducer';
import { SuperCustomerPageStoreEffects } from './effects';


@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(FeatureName, superCustomerPageReducer),
		EffectsModule.forFeature([SuperCustomerPageStoreEffects])
	]
})

export class SuperCustomerPageStoreModule { }
