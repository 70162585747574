
import { createAction, props } from '@ngrx/store';
import { IAccountingItemList, IAccountingListFilter } from 'apps/federation/src/app/accounting-module/models';
import { ISuperCustomerIdentity, ICustomersList } from 'apps/federation/src/app/customers-module/models';
import { CustomersListSortProp } from 'apps/federation/src/app/customers-module/enums';
import { AccountingListSortProp } from 'apps/federation/src/app/accounting-module/enums';
import { IFilter } from '@aston/foundation';

import { createEntityActions, createListActions, createDebugAction, createErrorAction } from '../functions';
import { CorrelationParams } from '../models/correlated-actions.model';

const ActionPrefix = 'Super Customer Page';

export const Debug = createDebugAction(`[${ActionPrefix}] `);

export const InitSuperCustomerPageRequest = createAction(`[${ActionPrefix}] Init Request`,
	props<{ correlationParams?: CorrelationParams, id: number }>()
);

export const InitSuperCustomerPageSuccess = createAction(`[${ActionPrefix}] Init Success`);
export const InitSuperCustomerPageFailure = createErrorAction(`[${ActionPrefix}] Init`);

export const ReloadAccountingData = createAction(`[${ActionPrefix}] Reload Accounting Data`);

export const superCustomerIdentityActions = createEntityActions<ISuperCustomerIdentity>(ActionPrefix, 'SuperDebtor Identity');
export const {
	LoadEntityByIdRequest: LoadSuperCustomerIdentityRequest,
	LoadEntitySuccess: LoadSuperCustomerIdentitySuccess,
	LoadEntityFailure: LoadSuperCustomerIdentityFailure,
} = superCustomerIdentityActions;

export const superCustomerAccountingDocumentsActions = createListActions<IAccountingItemList, AccountingListSortProp, IAccountingListFilter>(
	ActionPrefix,
	'SuperDebtor Accounting Documents');
export const {
	LoadListRequest: LoadAccountingRequest,
	LoadListSuccess: LoadAccountingSuccess,
	LoadListFailure: LoadAccountingFailure,
	ListSettings: UpdateAccountingSettings,
	AddListFilters: AddAccountingFilters,
	ResetListFilters: ResetAccountingFilters,
	RemoveListFilters: RemoveAccountingFilters,
	ExportListRequest: ExportAccountingRequest,
	ExportListSuccess: ExportAccountingSuccess,
	ExportListFailure: ExportAccountingFailure,
} = superCustomerAccountingDocumentsActions;

export const superCustomerDebtorsActions = createListActions<ICustomersList, CustomersListSortProp, null>(ActionPrefix, 'SuperDebtor Debtors');
export const {
	LoadListRequest: LoadDebtorsRequest,
	LoadListSuccess: LoadDebtorsSuccess,
	LoadListFailure: LoadDebtorsFailure,
	ListSettings: UpdateDebtorsSettings
} = superCustomerDebtorsActions;

export const superCustomerAttachedDebtorsActions = createListActions<ICustomersList, CustomersListSortProp, IFilter>(ActionPrefix, 'SuperDebtor AttachedDebtors');
export const {
	LoadListRequest: LoadAttachedDebtorsRequest,
	LoadListSuccess: LoadAttachedDebtorsSuccess,
	LoadListFailure: LoadAttachedDebtorsFailure,
	ListSettings: UpdateAttachedDebtorsSettings,
	AddListFilters: AddAttachedDebtorsFilters,
	RemoveListFilters: RemoveAttachedDebtorsFilters,
} = superCustomerAttachedDebtorsActions;

export const allSuperCustomerAttachedDebtorsActions = createListActions<ICustomersList, CustomersListSortProp, IFilter>(ActionPrefix, 'SuperDebtor All AttachedDebtors');
export const {
	LoadListRequest: LoadAllAttachedDebtorsRequest,
	LoadListSuccess: LoadAllAttachedDebtorsSuccess,
	LoadListFailure: LoadAllAttachedDebtorsFailure,
} = allSuperCustomerAttachedDebtorsActions;

export const UpdateGroupMaster = createAction(`${ActionPrefix} Update GroupMaster`, props<{ superCustomerId: number, masterCustomerId: number }>());
