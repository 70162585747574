import { ScoringRiskType } from "apps/federation/src/app/customers-module/enums";

import { IHomePieData, IHomeScoringSlices, IHomeTurnoverChartData, ITopDebtorsWithClaims } from "../models";
import { IHomeAgeingBalanceAmountKpisApi, IHomeScoringSlicesApi, ITopDebtorsWithClaimsApi, IHomeWorldMapKpisApi, ITotalRevenueKpisApi } from "../models/api";


export function mapToTopDebtorsWithClaims(model: ITopDebtorsWithClaimsApi): ITopDebtorsWithClaims {
	return model.map(item => ({
		...item
	}))
}

export function mapToRiskName(risk: ScoringRiskType): string {
	return 'SuperDebtors.Scoring.Table.RiskNames.' + ({
		[ScoringRiskType.Low]: 'Lowest',
		[ScoringRiskType.Moderate]: 'Low',
		[ScoringRiskType.High]: 'High',
		[ScoringRiskType.Strong]: 'Highest',
	}[risk] || 'Low');
}

export function mapRiskNameToRange(risk: ScoringRiskType): number[] {
	return {
		[ScoringRiskType.Low]: [15, 20],
		[ScoringRiskType.Moderate]: [10, 15],
		[ScoringRiskType.High]: [5, 10],
		[ScoringRiskType.Strong]: [-1, 5],
	}[risk];
}

export function mapToScoringSlices(model: IHomeScoringSlicesApi): IHomeScoringSlices {
	return model.map(item => ({
		slice: mapToRiskName(item.scoringRiskType),
		riskType: item.scoringRiskType,
		count: item.superCustomerCount,
		totalOutstanding: item.totalOutstandingAmount,
		expiredOutstanding: item.expiredOutstandingAmount,
	}))
}

export function ageingBalanceAccountingDocumentCountToGraphData(model: IHomeAgeingBalanceAmountKpisApi[]): IHomePieData {
	return model.map(item => ({
		name: item.label,
		value: item.accountingDocumentCount,
		position: item.position,
	}))
}

export function ageingBalanceTotalOutstandingAmountToGraphData(model: IHomeAgeingBalanceAmountKpisApi[]): IHomePieData {
	return model.map(item => ({
		name: item.label,
		value: item.totalOutstandingAmount,
		position: item.position,
	}))
}

export function mapToICountryMarkers(model: IHomeWorldMapKpisApi[]): any[] {
	return model.map(item => ({
		countryCode: item.countryCode.toUpperCase(),
		size: item.totalRevenues,
		variation: item.totalRevenuesIndicator.state,
		extras: {
			turnover: item.totalRevenues,
			totalOutstanding: item.totalOutstandingAmount,
			debtors: item.customerCount
		}
	}))
}

export function mapToHomeTurnoverChartData(model: ITotalRevenueKpisApi): IHomeTurnoverChartData {
	return model.map(item => ({
		monthDate: item.monthDate,
		totalRevenues: item.totalRevenues,
		averageRevenues: item.medianRevenues,
	}))
}
